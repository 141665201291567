<template>
    <div class="row patient_chart">
        <Sidebar></Sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history">
            <div class="table-responsive appointment_detail">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-65 cursor-default"></th>
                            <th scope="col" class="img-fluid appointment-date" @click="getAppointmentOrderByData('appointment_date', 'appointment-date')">Appointment Date <img src="/images/down-arrow-new.svg"></th>
                            <th scope="col" class="img-fluid appointment-status" @click="getAppointmentOrderByData('status', 'appointment-status')">Status <img src="/images/down-arrow-new.svg"></th>
                            <th scope="col" class="img-fluid appointment-mode" @click="getAppointmentOrderByData('mode', 'appointment-mode')">Mode <img src="/images/down-arrow-new.svg"></th>
                            <th scope="col" class="img-fluid appointment-provider" @click="getAppointmentOrderByData('provider', 'appointment-provider')">Provider <img src="/images/down-arrow-new.svg"></th>
                            <th scope="col" class="img-fluid appointment-reason" @click="getAppointmentOrderByData('reason', 'appointment-reason')">Reason <img src="/images/down-arrow-new.svg"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <slot v-if="appointmentData.length > 0" >
                            <tr v-for="(item, index) in appointmentData" :key="index">
                                <td><span class="rectangle"></span></td>
                                <td>{{ item.appointment_date }}</td>
                                <td>{{ item.appointment_status }}</td>
                                <td>{{ item.appointment_type }}</td>
                                <td>{{ item.provider_name }}</td>
                                <td class="word-wrap-break-word">{{ item.reason_for_visit }}</td>
                            </tr>
                        </slot>
                        <slot v-else>
                            <tr>
                                <td colspan="5">No records found.</td>
                            </tr>
                        </slot>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from "jquery";
    import Sidebar from './Sidebar.vue';
    import axios from "@/scripts/axios.js";

    export default {
        data() {
            return {
                appointmentData:[]
            }
        },
        components: { Sidebar },
        methods: {
            getAppointmentOrderByData(param, className) {
                var orderBy = 'desc';
                if($('.'+className).hasClass('active'))
                {
                    $('.'+className).removeClass('active');
                    orderBy = 'desc';
                }
                else
                {
                    $('.'+className).addClass('active');
                    orderBy = 'asc';
                }
                const url = "appointment/order-by";
                const patient_id = this.$route.params.patient_id;
                let $this = this;
                axios.post(url, { patient_id: patient_id, filter: param, required_order: orderBy})
                    .then((response) => {
                        if (response.status == 200) {
                            $this.appointmentData = response.data.data;
                        }
                    });
            },
            getAppointmentData() {
                this.$store.state.loader = true;
                const url = "appointment/list";
                const patient_id = this.$route.params.patient_id;
                let $this = this;
                axios.post(url, { patient_id: patient_id, 'ony_appointment':1 })
                    .then((response) => {
                        if (response.status == 200) {
                            $this.appointmentData = response.data.data;
                            this.$store.state.loader = false;
                        } else {
                            this.$store.state.loader = false;
                        }
                }).catch(error => {
                    this.$filters.moshaToast(error.message, "error")
                    this.$store.state.loader = false;
                });
            },
        },
        mounted() {
            this.getAppointmentData()
        },
    }
</script>