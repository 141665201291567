<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies mesurement">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Metric Records" v-model="form.keyword" @keydown.enter="vitalSearch" @input="handleInput">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getPatientVitalList"></CustomFilter>
                    </span>
                </div>
                <div>
                    <button class="comman_btn" @click="addmeasurements"> Add Measurements </button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-50px" style="width: calc(var(--scale-ratio) * 150px)"></th>
                            <!-- <th scope="col" :class="{ 'active': this.sortBy == 'metric_group' }" @click="changeSort('metric_group')">Group <img src="/images/down-arrow-new.svg" class="img-fluid " :class="{ 'rotate180': this.sortOrder == 'asc' }"></th> -->
                            <th scope="col" :class="{ 'active': this.sortBy == 'metric_name' }" @click="changeSort('metric_name')">Metric <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'updated_datetime' }" @click="changeSort('updated_datetime')" style="width: calc(var(--scale-ratio) * 300px)">Last Updated Time <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'metric_value' }" @click="changeSort('metric_value')">Latest Value <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'metric_unit' }" @click="changeSort('metric_unit')">Unit <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.PatientVitalList" :key="index">
                            <td></td>
                            <!-- <td>{{ item.vital_group_name }}</td> -->
                            <td>{{ item.vital_name }}</td>
                            <td>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD') }}</td>
                            <td>{{ item.value }}</td>
                            <td>{{ item.vital_unit }}</td>
                            <td><span class="popup_link" @click="viewlogs(item.vital_id)"> View Logs</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="measurement_model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl metric-measurement-model">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeLogListModel"></button>
                    <div class="box">
                        <!-- <div id='Chart'>
                            <ejs-chart id="container" :title='title' :primaryXAxis='primaryXAxis'>
                                <e-series-collection>
                                    <e-series :dataSource='seriesData' type='Line' xName='recorded_chart_date' yName="value"> </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div> -->
						<div class="table-responsive specialist-list">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">ENTERED DATE</th>
                                        <th scope="col">VALUES</th>
                                        <th scope="col">UNIT</th>
                                        <th scope="col">% Change</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <slot v-for="(item, index) in patientVitalLogListData" :key="index">
                                        <tr v-if="index == 0">
                                            <td>{{ item.recorded_date }}</td>
                                            <td>{{ item.value }}</td>
                                            <td>{{ item.unit }}</td>
                                        </tr>
                                        <tr v-else>
                                            <td>{{ item.recorded_date }}</td>
                                            <td>{{ item.value }}</td>
                                            <td>{{ item.unit }}</td>
                                            <td :class="item.change_class">{{ item.percentage_change }}</td>
                                        </tr>
                                    </slot>
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade" id="add_measurement" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl new-measurements-modal">
			<div class="modal-content">
				<div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="resetFormData"></button>
                    <div class="box">
                        <div>
                             <h2 class="popup_title">Add New Measurements</h2>
                            <div class="tabs_part">
                                <div class="main_tagline">Metric Groups <span class="popup_link" v-if="is_active_vital_group" @click="clearGroupSelection"> Clear Selection</span></div>
                                <div class="tab_btn metric-tabs-group" id="measurement_group_list">
                                    <slot v-for="(item, index) in vitalGroupData" :key="index">
                                        <button class="comman_brdr_btn ms-3 fs-18" 
                                            :class="{ 'active': item.is_active }"
                                            @click="getGroupVitalMetrics(item.id, index)"> {{ item.name }} </button>
                                    </slot>
                                </div>
                                <div class="main_tagline">Measurements</div>
                            </div>
                                <div class="table-responsive new-measurements-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" style="width:calc(var(--scale-ratio) * 310px)">Metric<small class="asterisksign">*</small></th>
                                            <th scope="col">Values<small class="asterisksign">*</small></th>
                                            <th scope="col">Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody class="add_data" v-if="is_active_vital_group == false">

                                        <tr v-for="(item, index) in patient_vital_form.vital" :key="index">
                                            <td>
                                                <div class="minus_sign_btn" 
                                                    v-if="index !== 0"
                                                    @click="removePatientVitalFromForm(index)">
                                                    -
                                                </div>
                                            </td>
                                            <td>
                                                <div class="matric-vitalList-select position-relative" style="width:calc(var(--scale-ratio) * 270px)">
                                                    <CustomDropDown :options="vitalList" :initialValue="item.display_txt" @item-selected="handleItemSelected" :fieldName="getFieldName(index)"></CustomDropDown>
                                                    <!-- <input type="text" class="form-control" v-model="item.name" @keyup="checkVitalSearchLength(index)" style="width: 270px;"> -->
                                                    <div class="search_drop_box" v-if="searchVitalList.length > 0 && vital_search == true">
                                                        <slot v-for="(item, listIndex) in searchVitalList" :key="listIndex">
                                                            <div class="con_drop_line" @click="selectVital(item.id, item.name, item.unit)">
                                                                <div class="man_haeding">
                                                                    <span>{{ item.name }} - ({{ item.unit }})</span>
                                                                </div>
                                                            </div>
                                                        </slot>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><input type="text" class="form-control" v-model="item.value" style="width: calc(var(--scale-ratio) * 300px);"></td>
                                            <td><input type="text" class="form-control" v-model="item.unit" style="width: calc(var(--scale-ratio) * 119px);" :readonly="!item.is_custom_unit"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td class="w-auto" colspan="7"><div class="plus_sign_row" @click="add_new" style="width: 97%;padding: calc(var(--scale-ratio) * 9.5px);">+</div></td>
                                        </tr>

                                    </tbody>
                                    <tbody class="add_data" v-if="is_active_vital_group == true">
                                        <tr v-for="(item, index) in patient_vital_form.vital" :key="index">
                                            <td></td>
                                            <td><input type="text" class="form-control" v-model="item.name" readonly></td>
                                            <td><input type="text" class="form-control" v-model="item.value"></td>
                                            <td><input type="text" class="form-control" v-model="item.unit" :readonly="!item.is_custom_unit"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="comman_brdr_btn popup_close big_btn mx36" data-bs-dismiss="modal" aria-label="Close" @click="resetFormData"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="savePatientVital">
                                <img src="images/loader.gif" v-if="loader" />
                                Create
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
// import { ChartComponent, SeriesCollectionDirective, SeriesDirective, LineSeries, Legend, Category } from "@syncfusion/ej2-vue-charts";
import $ from "jquery";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';

export default {
        data() {
            return {
                add_data: 1,
                sortBy:'',
                sortOrder:'',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "metric_group", title: "Group" },
                    { value: "metric_name", title: "Metric" },
                    { value: "metric_value", title: "latest Value" },
                    { value: "metric_unit", title: "Unit" },
                ],
                selectChk:[],
                searchVitalList : [],
                vital_list : [],
                form:{
                    keyword: null,
                    advanceFilter: null,
                },
                patient_vital_form:{
                    patient_id: this.$route.params.patient_id,
                    vital_group_id: null,
                    vital: [{
                        name:null,
                        value: null,
                        unit: null,
                        vital_id: null,
                        is_custom_unit:false
                    }],
                },

                loader: false,
                vitalGroupData: [],
                is_active_vital_group: false,
                vital_search: false,
                PatientVitalList: [],
                reversedVitalArray: [],
                patientVitalLogListData: [],

                title: '',
                primaryXAxis: {
                    valueType: 'Category'
                },
                seriesData: [],
                vitalList: [],
                searchTimeout: null,
            }
        },
        // provide: {
        //     chart: [ LineSeries, Legend, Category ]
        // },
        components: {
            sidebar,
            CustomFilter,
            // 'ejs-chart' : ChartComponent,
            // 'e-series-collection' : SeriesCollectionDirective,
            // 'e-series' : SeriesDirective,
            CustomDropDown
        },
        mounted(){
            document.addEventListener('click', this.handleClickOutside);
            this.getPatientVitalList()
            this.getCustomVitalList()
            this.getVitalGroupList()
        },
        methods:{
            handleInput() {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                // Start a new timer that triggers after 2 seconds of idle time
                this.searchTimeout = setTimeout(() => {
                    this.vitalSearch();
                }, 2000); // 2-second idle time
            },
            vitalSearch()
            {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                this.getPatientVitalList();
            },
            clearGroupSelection()
            {
                this.vitalGroupData.forEach((item) => {
                    item.is_active = false;
                });
                this.add_data = 1;
                this.is_active_vital_group = false;
                this.patient_vital_form.vital_group_id = null;
                this.patient_vital_form.vital = [{
                    name:null,
                    value: null,
                    unit: null,
                    vital_id: null,
                    is_custom_unit: false,
                }];
            },
            removePatientVitalFromForm(index) {
                this.patient_vital_form.vital.splice(index, 1);
            },
            selectVital(id, name, unit) {
                const exists = this.patient_vital_form.vital.some(item => item.id === id && item.name === name);

                if (!exists) {
                    let index = 0;

                    this.patient_vital_form.vital[index].vital_id = id;
                    this.patient_vital_form.vital[index].name = name;
                    this.patient_vital_form.vital[index].unit = unit;
                    this.patient_vital_form.vital[index].display_txt = name;
                    this.patient_vital_form.vital[index].is_custom_unit = true;
                    this.searchVitalList = [];
                    this.vital_search = false;
                }
                else 
                {
                    this.$filters.moshaToast("These vital is already exist", "error");
                }
            },
            checkVitalSearchLength(index) {
                let searchText = this.patient_vital_form.vital[index].name.trim().toLowerCase();
                if (searchText.length > 2) {
                    this.vital_search = true;

                    this.searchVitalList = this.vital_list.filter(item => {
                        return item.name.toLowerCase().includes(searchText);
                    });
                } else {
                    this.vital_search = false;
                    this.searchVitalList = [];
                }
            },
            searchInVitalList(index) {
                let obj = this.patient_vital_form.vital_name[index];
                const searchTerm = obj.toLowerCase();
                const searchVitalList = [];
                for (const VitalObj of this.vital_list) {
                    const matchesSearchTerm = Object.values(VitalObj).some((value) => {
                        if (value != null) {
                            if (value.toString().toLowerCase().includes(searchTerm)) {
                                return true;
                            }
                        }
                    });
                    if (matchesSearchTerm) {
                        searchVitalList.push(VitalObj);
                    }
                }
                if (searchVitalList.length > 0) {
                    const uniqueObjects = Array.from(new Set(searchVitalList.map(obj => JSON.stringify(obj))));
                    const VitalUniqueList = uniqueObjects.map(objString => JSON.parse(objString));

                    this.searchVitalList = VitalUniqueList.map((item, index) => {
                        const srt = '<a id="link-Vital-item-' + index + '" class="dropdown-item" href="#"><div class="d-flex justify-content-between">' + item + '</div></a>'
                        return { ...item, name: srt }
                    });
                    console.log('searchVitalList:- ', this.searchVitalList);
                }
                return null;
            },
            add_new() {
                const lastItemIndex = this.patient_vital_form.vital.length - 1;
                if ( this.patient_vital_form.vital[lastItemIndex].name !== null && this.patient_vital_form.vital[lastItemIndex].value !== null) {
                    let obj = {
                        name: null,
                        value: null,
                        unit: null,
                        vital_id: null,
                        display_txt:"Select Vital"
                    };
                    // Add the new item at the end of the array
                    this.patient_vital_form.vital.unshift(obj);
                } else {
                    this.$filters.moshaToast("Required To Add Current Vital Data", "error");
                }
            },
            viewlogs(vitalId){
                this.patientVitalLogList(vitalId);
            },
            patientVitalLogList(vitalId) {
                this.$store.state.loader = true;
                axios.post("patient/vital/vital-log-list",{
                    patient_id: this.$route.params.patient_id,
                    id: vitalId,
                })
                .then((response) => {
                    console.log('patientVitalLogListData:- ', response);
                    if(response.data.status == 200)
                    {
                        this.patientVitalLogListData = response.data.data.data;
                        this.title = response.data.data.title;
                        this.seriesData = response.data.data.data;
                        this.primaryXAxis.valueType = response.data.data.title,
                        window.$("#measurement_model").modal("show");
                        console.log('seriesData:- ', this.seriesData);
                        this.$store.state.loader = false;
                    }
                })
                .catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            closeLogListModel()
            {
                window.$("#measurement_model").modal("hide");
                this.patientVitalLogListData = [];
                this.title = '';
                this.seriesData = [];
                this.primaryXAxis.valueType = '';
                const queryId = this.$route.query.id;
                if (queryId && Number(queryId) > 0) {
                    this.$router.push({ name: 'patientMeasurementList', params: {patient_id: this.$route.params.patient_id} });
                }
            },
            addmeasurements(){
                this.vitalMasterList()
                window.$("#add_measurement").modal("show");
            }, 
            getPatientVitalList(){
                this.$store.state.loader = true;
                axios.post("patient/vital/list",{
                    patient_id: this.$route.params.patient_id,
                    keyword: this.form.keyword,
                    advanceFilter:this.form.advanceFilter,
                    sort_by: this.sortBy,
                    sort_order:this.sortOrder
                })
                .then((response) => {
                    if(response.data.status == 200)
                    {
                        this.PatientVitalList = response.data.data;
                        this.$store.state.loader = false;
                    }
                })
                .catch(error => {
                    this.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getPatientVitalList()
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getPatientVitalList()
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
            },
            getCustomVitalList(){
                axios.post("custom-vital/list")
                    .then((response) => {
                        if (response.status == 200) {
                            this.vital_list = response.data.data;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            savePatientVital(){
                this.loader = true;
                let validationPassed = true;
                for (const item of this.patient_vital_form.vital) {
                    for (const field of ['name', 'value']) {
                        if (!item[field]) {
                            const fieldName = field === 'name' ? 'Vital Metrics Name' : 'Vital Metrics Value';
                            this.$filters.moshaToast(`Required,  ${fieldName}`, "error");
                            validationPassed = false;
                            this.loader = false;
                            break;
                        }
                    }
                    if (!validationPassed) break;
                }

                if(validationPassed)
                {
                    axios.post("patient/vital/store", this.patient_vital_form)
                    .then((response) => {
                        this.loader = false;
                        if(response.data.status == 200)
                        {
                            this.resetFormData();
                            this.getPatientVitalList();
                            window.$("#add_measurement").modal("hide");
                            this.$filters.moshaToast(response.data.message, "success");
                        }
                    })
                    .catch(error => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
                }
            },
            getVitalGroupList()
            {
                axios.post("vital-group/list")
                .then((response) => {
                    if(response.data.status == 200)
                    {
                        this.vitalGroupData = response.data.data;
                    }
                })
                .catch(error => {
                    this.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            getGroupVitalMetrics(id, index){
                this.vitalGroupData = this.vitalGroupData.map((item, i) => {
                    item.is_active = (i === index);
                    return item;
                });
                axios.post("vital-group/retrieve-group", { id: id })
                .then((response) => {
                    this.is_active_vital_group = true;
                    this.patient_vital_form.vital = response.data.data[0].group_matrix_list;
                    this.patient_vital_form.vital_group_id = id;
                    this.patient_vital_form.vital.forEach((item) => {
                        item.is_custom_unit = item.unit === '' || item.unit === undefined || item.unit === null;
                    });

                })
                .catch(error => {
                    this.loader = false;
                    if (error.response.status == 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            resetFormData()
            {
                this.patient_vital_form = {
                    patient_id: this.$route.params.patient_id,
                    vital_group_id : null,
                    vital: [{
                        name:null,
                        value: null,
                        unit: null,
                        vital_id: null,
                        display_txt:"Select Vital"
                    }],
                };
                this.is_active_vital_group = false;
                this.add_data = 1;
                this.vitalGroupData.forEach((item) => {
                    item.is_active = false;
                });
                this.searchVitalList = [];
            },
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getPatientVitalList()
                    this.isShowCustomFilter = false;
                }

            },
            vitalMasterList() {
                this.vitalList = [];
                axios.post("json_autocomplete/vital-search")
                    .then((response) => {
                        const vitalTempData = []
                            const details = response.data.data;
                            $.each(details, function (key, detail) {
                                let title = detail['name']
                                if (detail['unit']) {
                                    title += '('+detail['unit']+')'
                                }

                                vitalTempData.push({
                                    value: detail['id'],
                                    title: title,
                                    unit:detail['unit'],
                                    display_txt:"Select Vital",
                                    is_custom_unit:true
                                })
                            })
                            this.vitalList = vitalTempData;
                    })
                    .catch((error) => {
                        this.loader = false;
                        if (error.response.status == 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            getFieldName(index) {
                return `patient_vital_form.vital.${index}`;
            },
            handleItemSelected({ fieldName, item }) {
                console.log(item)
                // Regular expression to match 'included_metrics.{digit}' and extract the digit
                const regex = /^patient_vital_form.vital\.(\d+)$/;
                const match = fieldName.match(regex);
                if (match) {
                    const index = parseInt(match[1]); // Extract the digit and convert it to an integer
                    
                    // Ensure included_metrics is an array
                    if (!Array.isArray(this.patient_vital_form.vital)) {
                        this.patient_vital_form.vital = [];
                    }
    
                    // Assign the value to the specific index of included_metrics array
                    let tempOpt = {
                        'name':item.title,
                        'unit':item.unit,
                        'vital_id':item.value,
                        'value':null,
                        'display_txt':item.title,
                        is_custom_unit : (item.unit === '' || item.unit === undefined || item.unit === null)
                    }
                    this.patient_vital_form.vital[index] = tempOpt;
                    console.log(tempOpt)
                }
            },
        },
        created(){
            this.$store.state.loader = true;
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                setTimeout(() => {
                    this.viewlogs(Number(queryId))
                }, 100);
            }
        },
        
}
</script>
<style scoped>
button.active { background-color: #0E2641; color: #F4D09E;}
</style>
