<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Allergies" v-model="allergy_search" @keydown.enter="allergenSearch" @input="handleInput" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn allergy_filter-btn" @click="addFilter" v-if="this.filterList.length == 0" > Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getAllergiesList"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="addAllergy" style="padding: calc(var(--scale-ratio) * 19px) calc(var(--scale-ratio) * 38.8px);"> Add Allergy </button>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-30px"></th>
                            <th scope="col" class="active" @click="getAllergiesOrderBy('updated_datetime', $event)">Create Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('description', $event)">Allergen <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('reaction', $event)">Reaction <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('severity_of_reaction', $event)">Severity <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getAllergiesOrderBy('life_stage', $event)">Life Stage <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allAllergiesList" :key="index">
                            <td></td>
                            <td>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD') }}</td>
                            <td>{{ this.capitalizeWords(item.description) }}</td>
                            <td>{{ this.capitalizeFirstLetter(item.reaction) }}</td>
                            <td v-if="item.severity_of_reaction != null">{{ item.severity_of_reaction }}</td>
                            <td v-if="item.severity_of_reaction == null">Not Set</td>
                            <td v-if="item.life_stage != null">{{ item.life_stage }}</td>
                            <td v-if="item.life_stage == null">Not Set</td>
                            <td><img src="/images/dots-icon.svg" class="dot_icon" @click="editAllergy(item.id)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-allergies-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAllergyModel"></button>
                    <div class="box">
						<h2 class="popup_title">{{ popupHeadingText }}</h2>
						<div class="date_line"><label>Create Date</label> <span>{{ this.currentDate }}</span></div>
                        <div class="custom-form">
                            <div class="position-relative seach_input_bar input_box allergiesDescription_list">
                                <div class="search input-container" style="position: relative;">
                                    <img class="serch_icon" src="/images/search-icon.svg" alt="" style="position: absolute; left: 10px; top: 50%; transform: translateY(-50%);">
                                    <input type="text" @input="drugSearch" class="form-control w-100" placeholder="Allergy Description" v-model="form.description" ref="globalSearch" style="padding-right: 30px; padding-left: 40px;">
                                    <img src="images/loader.gif" v-if="allegyDescLoader" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); background-color: white; padding: 5px; border-radius: 50%;">                                    
                                    <span class="required-star" v-if="isInputEmpty" style="position: absolute; top: calc(var(--scale-ratio)* 30px);left: calc(var(--scale-ratio)* 200px);transform: translateY(-50%);;">*</span>                                    
                                    <ValidationErrorMessageList :errors="v$.form.description.$errors" />
                                </div>
                                <div class="search_drop_box new_big_drop" v-if="drugList.length > 0">
                                    <slot v-for="(item, index) in drugList" :key="index">
                                        <div class="con_drop_line" @click="selectDrug(item)">
                                            <div class="man_haeding mb-0">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </slot>
                                </div>
                            </div>
                            <div class="input_box">
                                <input type="test" required="" id="typeEmailX-2" class="form-control form-control-lg" v-model="form.reaction">
                                <label class="form-label" for="typeEmailX-2">Reaction Description</label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="heading_selact_drodwn allergies_severity_select">
                                <CustomDropDown :options="severity_option" :initialValue="this.form.severity_of_reaction_text"
                                     @item-selected="handleItemSelected" labelText="Severity"
									fieldName="severity_of_reaction" divClass="form-label" :defaultSelectedClass="severityClass"></CustomDropDown>
                            </div>
                            <div class="heading_selact_drodwn life_stage_select">
                                <CustomDropDown :options="life_stage_options" :initialValue="this.form.life_stage_text"
                                    @item-selected="handleItemSelected" labelText="Life Stage"
									fieldName="life_stage" divClass="form-label" :defaultSelectedClass="lifeStageClass"></CustomDropDown>
                            </div>
                        </div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx42" @click="closeAllergyModel"> Cancel </button>
                            <button class="comman_btn big_btn mx42" @click="addAllergyCall">
                                {{ buttonName }}
                                <img src="images/loader.gif" v-if="loader" />
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';

export default {
        setup: () => {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                severity_option:[
					{ value: "", title: "Not Set" },
					{ value: "Mild", title: "Mild" },
					{ value: "Moderate", title: "Moderate" },
					{ value: "Severe", title: "Severe" },
				],
                life_stage_options: [
                    { value: "", title: "Not Set" },
                    { value: "N", title: "Newborn: Birth to 28 days" },
                    { value: "I", title: "Infant: 29 days to 2 years" },
                    { value: "C", title: "Child: 2 years to 15 years" },
                    { value: "T", title: "Adolescent: 16 to 17 years" },
                    { value: "A", title: "Adult: 18 years or more" },
                ],
                filterOptions:[
                    { value: "allergen", title: "Allergen" },
                    { value: "reaction_desc", title: "Reaction" },
                    { value: "serverity", title: "Severity" },
                    { value: "life_stage", title: "Life Stage" },
                ],
                isShowCustomFilter:false,

                allAllergiesList: [],
                orderBy: 'desc',
                orderByColumnName: 'created_datetime',
                buttonName: "Add",
                action: "Add",
                popupHeadingText: "Add New Allergies",
                loader: false,
                allegyDescLoader: false,
                filterList:[],
                currentDate:null,
                drugList: [],
                severityClass: '',
                lifeStageClass: '',
                searchTimeout: null,
            }
        },
        components: {
            sidebar,
            CustomDropDown,
            CustomFilter,
            ValidationErrorMessageList
        },
        mounted() {
            this.getAllergiesList();
            document.addEventListener('click', this.handleClickOutside);
        },
        created(){
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                setTimeout(() => {
                    this.editAllergy(Number(queryId))
                }, 100);
            }
        },
        methods:{
            handleInput() {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                // Start a new timer that triggers after 2 seconds of idle time
                this.searchTimeout = setTimeout(() => {
                    this.allergenSearch();
                }, 2000); // 2-second idle time
            },
            capitalizeWords(value) {
                if (!value) return '';

                let lowerCased = value.toLowerCase();

                return lowerCased.replace(/\b\w/g, (char, index, str) => {
                    if (index > 0 && str[index - 1] === "'") {
                        return char;
                    }
                    return char.toUpperCase();
                });
            },
            capitalizeFirstLetter(text) {
                if (!text) return '';
                return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            },
            handleClickOutside(event) {
				const globalSearchElement = this.$refs.globalSearch;
				if (globalSearchElement && !globalSearchElement.contains(event.target)) {
					this.drugList = [];
				}

                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getAllergiesList();
                    this.isShowCustomFilter = false;
                }
			},
            selectDrug(item){
                this.form.description = this.capitalizeWords(item.name);
                this.form.pepid_code = item.id
			},
            drugSearch() {
                if(this.form.description != '') {
                    this.allegyDescLoader = true;
                    this.drugList = [];
                    
                    setTimeout(() => {
                        axios.post("json_autocomplete/drug-class/list", { search_text: this.form.description })
                            .then((response) => {
                                this.drugList = response.data.data;
                                this.allegyDescLoader = false;
                            });
                    }, 1000);
                }
            },
            resetAllergyForm() {
                this.v$.form.$reset();
                Object.assign(
                    this.$store.state.allergies.form,
                    this.$store.state.allergies.defaultFormData,
                );
                this.action = "Add";
                this.buttonName = "Add";
                this.popupHeadingText = "Add New Allergies";
                this.form.description = '';
                this.drugList = []
                this.currentDate = new Date().toISOString().split('T')[0]
                this.loader = false;
                this.lifeStageClass = '';
                this.severityClass = '';
            },
            getValueFromLifeStages(targetValue) {
                for (const option of this.life_stage_options) {
                    if (option.title.includes(targetValue)) {
                        return option.value;
                    }
                }
                return null;
            },
            getLifeStageTitleVal(value) {
                const option = this.life_stage_options.find(option => option.value === value);
                if (option) {
                    const title = option.title;
                    const index = title.indexOf(":");
                    return index !== -1 ? title.substring(0, index).trim() : "Not Set";
                } else {
                    return "Not Set";
                }
            },
            getLifeStageTitle() {
                this.allAllergiesList.forEach(item => {
                    const lifeStageOption = this.life_stage_options.find(option => option.value === item.life_stage);
                    if (lifeStageOption) {
                        const titleParts = lifeStageOption.title.split(':');
                        item.life_stage = `${item.life_stage} - ${titleParts[0].trim()}`;
                    }
                });
            },
            closeAllergyModel() {
                window.$("#add-allergies-model").modal("hide");
                const queryId = this.$route.query.id;
                if (queryId && Number(queryId) > 0) {
                    this.$router.push({ name: 'PatientEChartAllergies', params: {patient_id: this.$route.params.patient_id} });
                }
            },
            addAllergy(){
                this.resetAllergyForm();
                window.$("#add-allergies-model").modal("show");
            },
            getAllergiesList() {
                this.$store.state.loader = true;
                this.form.patient_id = this.$route.params.patient_id;
                this.form.allergy_search = this.allergy_search;  
                axios.post('patient/allergies/list', this.form)
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
                            this.allAllergiesList = response.data.data;
                            if(this.allAllergiesList) {
                                this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                this.getLifeStageTitle();
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.$store.state.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            getAllergiesOrderBy(orderColumnName, event){
                event.stopPropagation(); 

                const th = event.target;
                this.orderByColumnName = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.orderBy = 'asc';
                } else {
                    th.classList.add('active');
                    this.orderBy = 'desc';
                }

                axios.post('patient/allergies/list', { patient_id: this.$route.params.patient_id, order_by_column: this.orderByColumnName, order_by: this.orderBy })
                    .then((response) => {
                        if (response.status == 200) {
                            this.allAllergiesList = response.data.data;
                            if(this.allAllergiesList) {
                                this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                this.getLifeStageTitle();
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            addAllergyCall() {
                let url = '';
                if(this.form.id) {
                    url = 'patient/allergies/update'
                } else {
                    url = 'patient/allergies/store'
                    this.form.start_date = this.$filters.inputDateTime(this.$filters.getCurrentDateTime(), 'YYYY-MM-DD')
                }
                this.form.patient_id = this.$route.params.patient_id;
                this.form.life_stage = this.getValueFromLifeStages(this.form.life_stage);
                
                const formName = "form";
                this.v$[formName].$validate();
                if (!this.v$[formName].$error) {
                    this.loader = true;
                    axios.post(url, this.form)
                        .then((response) => {
                            if (response.status == 200) {
                                this.loader = false;
                                this.getAllergiesList();
                                if(this.allAllergiesList) {
                                    this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                                    this.getLifeStageTitle();
                                }
                                
                                this.$filters.moshaToast(response.data.message, "success");
                                const queryId = this.$route.query.id;
                                if (queryId && Number(queryId) > 0) {
                                    this.closeAllergyModel();
                                    this.$router.push({ name: 'PatientEChartAllergies', params: {patient_id: this.$route.params.patient_id} });
                                } else {
                                    this.closeAllergyModel();
                                }
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.loader = false;
                            if (error.response.status === 422) {
                                console.log(Object.values(error.response.data.data).join(', '));
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                            });
                }
            },
            editAllergy(allergyId){
                this.severityClass = '';
                this.lifeStageClass = '';
                axios.post('patient/allergies/retrieve', { patient_id: this.$route.params.patient_id, id: allergyId })
                .then((response) => {
                    if (response.status == 200) {
                        window.$("#add-allergies-model").modal("show");
                        this.buttonName = "Update";
                        this.action = "Update";
                        this.popupHeadingText = "Update Existing Allergy";
                        let detail = response.data.data;

                        this.$store.state.allergies.form = detail;
                        this.$store.state.allergies.form.description = this.capitalizeWords(detail.description);
                        this.form.severity_of_reaction_text = (this.severity_option.find(item => item.value === this.form.severity_of_reaction) || {}).title || "Not Set";
                        if(this.form.severity_of_reaction_text !=  "" && this.form.severity_of_reaction_text != "Not Set") {
                            this.severityClass = 'active';
                        }
                        this.form.life_stage_text = (this.life_stage_options.find(item => item.value === this.form.life_stage) || {}).title || "Not Set";
                        if (this.form.life_stage_text != 'Not Set'){
                            this.form.life_stage_text = this.form.life_stage_text.substring(0, this.form.life_stage_text.indexOf(":")).trim();
                            this.lifeStageClass = 'active';
                        }

                        this.currentDate = this.$filters.inputDateTime(detail.start_date, 'YYYY-MM-DD')
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            allergenSearch() {
                clearTimeout(this.searchTimeout);
                if(this.allergy_search.length > 2)
                {
                    this.getAllergiesList();
                }
                if(this.allergy_search.length == 0)
                {
                    this.getAllergiesList();
                    if(this.allAllergiesList) {
                        this.allAllergiesList = this.capitalizeData(this.allAllergiesList);
                        this.getLifeStageTitle();
                    }
                }
            },
            handleItemSelected(payload) {
                const { fieldName, item } = payload;
                if(fieldName == "severity_of_reaction"){
                    this.form.severity_of_reaction = item.value;
                    this.form.severity_of_reaction_text = item.title
                }
                if(fieldName == "life_stage")
                {
                    this.form.life_stage = this.getLifeStageTitleVal(item.value);
                    this.form.life_stage_text = this.getLifeStageTitleVal(item.value);
                }
                // this.getAllergiesList();
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    description: this.$filters.strToUpperCase(item.description),
                    reaction: this.$filters.strToUpperCase(item.reaction)
                }));
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
                this.getAllergiesList();
            },
            handleCustomField(values){
                console.log("at here to heer");
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    advanceFilter[item.field] = item.value
                });
                this.form.advanceFilter = advanceFilter
                this.getAllergiesList()
            },
        },
        computed: {
            form() {
                return this.$store.state.allergies.form;
            },
            isInputEmpty() {
                return !(this.form.description && this.form.description.length > 0);
            }
        },
        validations() {
            return this.$store.state.allergies.validationRules;
        },
}
</script>