<template>
    <div class="viewprescription">
        <div class="addprescription justify-content-end">
            <div class="btn_part">
                <button class="comman_brdr_btn big_btn" @click="cancelFrm"> Cancel </button>
                <button class="comman_btn big_btn" @click="this.save('save')" v-if="this.checkPermission()"> Save <img src="images/loader.gif" v-if="savingloader"  style="width:calc(var(--scale-ratio) * 18px);" /></button>
                <button class="comman_btn big_btn" @click="this.save('save_print')" v-if="this.checkPermission()"> Save & Print/Fax <img src="images/loader.gif" v-if="savingPrintloader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
            </div>
        </div>

        <div class="prescription_box_scroll" >
            <div class="prescription_box">
                <div class="row">
                    <div class="col-md-7">
                        <div class="left_prescription">
                            <div class="haeding_line d-flex align-items-center justify-content-between">
                                <aside>Prescription 1</aside>
                            </div>

                            <div class="mr-30">
                                <div class="mb-0 row pd-bottom align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor">Generic Name</label>
                                    <div class="col-sm-8"><input v-if="isDropdownVisible" type="text" class="form-control" v-model="form.generic_name" @change="generateNotes()" readonly></div>
                                </div>
                                <div class="mb-10 row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor">Brand Name</label>
                                    <div class="col-sm-8"><input v-if="isDropdownVisible" type="text" class="form-control" v-model="form.drug_name" @change="generateNotes()" :readonly="!this.checkPermission()"></div>
                                </div>
                                <div class="mb-10 row align-items-center l-h-n">
                                    <label for="text" class="col-sm-4 col-form-label right-bor mt-30">Instruction</label>
                                    <div class="col-sm-8">
                                        <div class="pr-25">
                                            <div class="row">
                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                                                    <div class="presxrpt-int-drop">
                                                        <label for="text" class="drop_label mb-5p">Method</label>
                                                        <CustomDropDown v-if="isDropdownVisible" :options="method_option" :initialValue="form.method" v-model="form.method" @item-selected="handleItemSelected" :fieldName="`method`"  @change="this.calculateNextRefilDate()" :isDisabled="!this.checkPermission()">
                                                        </CustomDropDown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-0 row">
                                    <label for="text" class="col-sm-4"></label>
                                    <div class="col-sm-8">
                                        <div class="pr-25">
                                            <div class="dropdown_list_ins">
                                                <div class="row">
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div class="instru-inpt-box">
                                                            <label for="text" class="drop_label">Dosage</label>
                                                            <input v-if="isDropdownVisible" type="number" class="form-control" v-model="form.dosage" min="0" @change="generateNotes()" :readonly="!this.checkPermission()">
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div class="presxrpt-int-drop">
                                                            <label for="text" class="drop_label">Dosage Unit</label>
                                                            <CustomDropDown v-if="isDropdownVisible" :options="dosage_unit_option" :initialValue="form.dosage_unit"
                                                                v-model="form.dosage_unit" @item-selected="handleItemSelected" :fieldName="`dosage_unit`" :isDisabled="!this.checkPermission()">
                                                            </CustomDropDown>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div class="presxrpt-int-drop">
                                                            <label for="text" class="drop_label">Route</label>
                                                            <CustomDropDown v-if="isDropdownVisible" :options="route_option" :initialValue="form.route"
                                                                v-model="form.route" @item-selected="handleItemSelected" :fieldName="`route`" :isDisabled="!this.checkPermission()">
                                                            </CustomDropDown>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div class="presxrpt-int-drop">
                                                            <label for="text" class="drop_label">Frequency</label>
                                                            <CustomDropDown v-if="isDropdownVisible" :options="frequency_option" :initialValue="form.frequency"
                                                                v-model="form.frequency" @item-selected="handleItemSelected" :fieldName="`frequency`" :isDisabled="!this.checkPermission()">
                                                            </CustomDropDown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div>
                                                            <label for="text" class="drop_label">Duration</label>
                                                            <input v-if="isDropdownVisible" type="number" class="form-control" v-model="form.duration" @change="this.calculateNextRefilDate()" step="1" min="0" :readonly="!this.checkPermission()">
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div class="presxrpt-int-drop">
                                                            <label for="text" class="drop_label">Duration Unit</label>
                                                            <CustomDropDown v-if="isDropdownVisible" :options="duration_unit_option" :initialValue="form.duration_unit"
                                                                v-model="form.duration_unit" @item-selected="handleItemSelected" :fieldName="`duration_unit`"  @change="this.calculateNextRefilDate()" :isDisabled="!this.checkPermission()">
                                                            </CustomDropDown>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div>
                                                            <label for="text" class="drop_label">Quantity</label>
                                                            <input v-if="isDropdownVisible" type="number" class="form-control" v-model="form.quantity" step="1" min="0" @change="generateNotes()" :readonly="!this.checkPermission()">
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                        <div>
                                                            <label for="text" class="drop_label">Repeats</label>
                                                            <input v-if="isDropdownVisible" type="number" class="form-control" v-model="form.repeats" step="1" min="0" @change="this.calculateNextRefilDate()" :readonly="!this.checkPermission()">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="next_rfl_date mt-10" v-if="(form.is_long_term || form.repeats >=1) && form.next_refill_date">
                                <span class="mr-10">Next Refill Date:</span>
                                <label>{{ form.next_refill_date }}</label>
                            </div>
                            <div class="pl-10 pr-30">
                                <div class="check_box">
                                    <span class="ps-0"><label>Long Term Medication</label><span class="ms-3"><input v-if="isDropdownVisible" class="form-check-input m-0 align-middle" type="checkbox" v-model="form.is_long_term" @change="this.calculateNextRefilDate()" :checked="form.is_long_term" :disabled="!this.checkPermission()" :class="containerLongtermClass"></span></span>
                                    <span><label>Substitution Now Allowed</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="form.is_patient_compliant" :checked="form.is_patient_compliant" :disabled="!this.checkPermission()" :class="containerSubClass"></span></span>
                                    <span><label>Past Medication</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="form.is_past_med" :checked="form.is_past_med" :disabled="!this.checkPermission()" :class="containerPastClass"></span></span>
                                </div>
                                <textarea class="form-control" rows="4" v-model="form.notes" :readonly="!this.checkPermission()"></textarea>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row patient_chart justify-content-center edit_record_page">
                            <div class="col-lg-5">
                                <h4 class="box-title mt-0">Preferred Pharmacy</h4>
                                <div class="box-body containt_detail">
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Pharmacy Name</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.pharmacy_name}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.address}} {{this.pharmacy_detail.city}} {{this.pharmacy_detail.state}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Postal Code</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.zip_code}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Fax Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.fax}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Phone Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.phone}}</div>
                                    </div>
                                    </div>
                                </div>
                                <h4 class="box-title">Signature</h4>
                                <div class="box-body containt_detail mb-3">
                                    <div id="signature-control">
                                    <div class="e-sign-heading"><span id="signdescription"></span></div>
                                    <div class="col-9 me-0 ms-auto" style="height: 60px;">
                                        <img :src="this.sigImgBase64" alt="Base64 Image" class="e-control e-signature e-lib" height="60" style="height: 100%; width: 100%;" width="429" v-if="this.sigImgBase64">
                                        <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1" height="60" style="height: 100%; width: 100%;" width="429" v-else></canvas>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="pdf_viewer">
                                    <!-- <ejs-pdfviewer 
                                        id="pdfViewer" 
                                        ref="pdfviewer" 
                                        :documentPath="documentPath" 
                                        :resourceUrl="resourceUrl"
                                        @documentLoad="onDocumentLoad" 						
                                        :enableTextSearch="true"
                                        :enableHyperlink="true"
                                        :enableMagnification="true"
                                        :enableToolbar="true"
                                        :enableNavigation="true"
                                        :enableThumbnail="false"
                                        :enableBookmark="true"
                                        :enableTextSelection="true"
                                        :enablePrint="true"
                                        :enableAnnotation="true"
                                        :enableFormFields="false"
                                        :enableFormDesigner="false" 
                                        :openFile='false'
                                    >
                                    </ejs-pdfviewer> -->
                                    <canvas id="pdfCanvas" ref="pdfCanvas" style="width: 100%; height: 100%;" @contextmenu="handleCanvasRightClick" ></canvas>
                                </div>
                                <div class="pdf-pagination-sticky" v-if="this.documentPath && isShowPagination">
                                    <div class="query-pagination text-center" v-if="totalPages > 1">
                                        <ul class="page-pagination p-0 mt-0">
                                            <li style="margin-right: 40px;">
                                                <img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="currentPage > 1"
                                                    @click="goToPreviousPage">
                                            </li>
                                            <li v-if="totalPages > 1 || currentPage === 1">{{ currentPage }}</li>
                                            <li v-if="totalPages > 1">/</li>
                                            <li v-if="totalPages > 1 || currentPage === 1">{{ totalPages }}</li>
                                            <li style="margin-left: 40px;">
                                                <img src="/images/right-arrow-pagen.svg" class="img-fluid"
                                                    v-if="currentPage < totalPages" @click="goToNextPage">
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="text-center">
                                        1/1
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn_part d-flex justify-content-center">
                            <button class="comman_brdr_btn mx30">Cancel</button>
                            <button class="comman_brdr_btn mx30">Print</button>
                            <button class="comman_btn mx30">Fax</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row prescription_html">

                            <div class="col-12">
                                <h2 class="popup_title">Prescription Rx</h2>
                                <p>
                                    {{ this.provider_detail.full_name }} | CPS ID - {{ this.provider_detail.cpsid }} {{ this.provider_detail.address }} {{ this.provider_detail.cell_phone }} FAX  {{ this.provider_detail.fax_phone }}
                                </p>

                                <h2 class="popup_title">Basic Info</h2>

                                <p>
                                    <label>Patient</label> 
                                    <span>{{ this.patient_detail.full_name }} </span>
                                    <span>{{ this.patient_detail.address }}
                                        {{ this.patient_detail.city }}, {{ this.patient_detail.state }}, {{ this.patient_detail.country }}
                                        {{ this.patient_detail.zip }}
                                        Tel {{ this.patient_detail.cell_phone }}</span>
                                </p>
                                <p>
                                    <label>date of birth</label> 
                                    <span>{{this.patient_detail.dob}} </span>
                                </p>
                                <p>
                                    <label>hin</label> 
                                    <span>{{this.patient_detail.health_insurance_no}} </span>
                                </p>
                                <p>
                                    <label>Service Date</label> 
                                    <span>{{ this.current_date }} </span>
                                </p>

                                <h2 class="popup_title">Medication</h2>
                                <p>
                                    <label>prescription 1 </label> 
                                    <span>{{ this.form.drug_name }} </span>
                                </p>

                                <p>
                                    <label>instruction</label> 
                                    <span v-html="this.form.notes"></span>
                                </p>

                            </div>

                        </div>

                        <div class="btn_part">
                                <button class="comman_brdr_btn"> Cancel </button>
                                <button class="comman_btn" @click="this.printPreview()"> Print <img src="images/loader.gif" v-if="savingloader" /></button>
                                <button class="comman_btn" @click="this.doFax"> Fax <img src="images/loader.gif" v-if="savingloader" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

</template>

<script>
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
// import {  Toolbar, Magnification, Navigation, LinkAnnotation, 
//          BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, 
//          Annotation } from '@syncfusion/ej2-vue-pdfviewer';

export default ({
    setup() {
        return { 
            v$: useVuelidate()
        }; 
    },
    validations() {
        return this.$store.state.prescriptions.validationRules;
    },
    data(){
        return {
            method_option:[
                { value: "Take", title: "Take" },
                { value: "Apply", title: "Apply" },
                { value: "Rub Well in", title: "Rub Well in" },
            ],
            route_option:[
                { value: "PO", title: "PO" },
                { value: "IM", title: "IM" },
                { value: "IV", title: "IV" },
                { value: "SC", title: "SC" },
                { value: "Patch", title: "Patch" },
                { value: "Cream", title: "Cream" },
                { value: "Drops", title: "Drops" },
                { value: "INH", title: "INH" },
                { value: "Nasal Medications", title: "Nasal Medications" },
                { value: "Puff", title: "Puff" }
            ],
            dosage_unit_option:[
                { value: "mL", title: "mL" },
                { value: "mg", title: "mg" },
                { value: "g", title: "g" },
                { value: "ucg", title: "ucg" },
                { value: "capsule", title: "capsule" },
                { value: "tablet", title: "tablet" },
                { value: "teaspoon", title: "teaspoon" },
                { value: "tablespoon", title: "tablespoon" },
                { value: "activation", title: "activation" }
            ],
            frequency_option:[
                { value: "Every Hour", title: "Every Hour" },
                { value: "Every 2 Hours", title: "Every 2 Hours" },
                { value: "Every Morning", title: "Every Morning" },
                { value: "Every Evening", title: "Every Evening" },
                { value: "Every Bedtime", title: "Every Bedtime" },
                { value: "Daily", title: "Daily" },
                { value: "2x Daily", title: "2x Daily" },
                { value: "3x Daily", title: "3x Daily" },
                { value: "4x Daily", title: "4x Daily" },
                { value: "Weekly", title: "Weekly" },
                { value: "2x Weekly", title: "2x Weekly" },
                { value: "Monthly", title: "Monthly" },
                { value: "2x Monthly", title: "2x Monthly" }
            ],
            duration_unit_option:[
                { value: "day (s)", title: "day (s)" },
                { value: "week (s)", title: "week (s)" },
                { value: "month (s)", title: "month (s)" }, 
            ],
            savingloader:false,
            savingPrintloader:false,
            current_date: new Date(),
            patient_detail:[],
            provider_detail:[],
            permissionList:[],
            pharmacy_detail:[],
            sigImgBase64:'',
            documentPath:null,
            resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
            isDropdownVisible: false,

            pdfDoc: null,
            existingPdfBytes: null,
            totalPages: 0,
            currentPage: 1,
            isShowPagination: false,
        }
    },
    components: {
        CustomDropDown
    },
    methods:{
        getPreferredPharamcy(){
            axios.post("patient/get-preferred-pharamcy", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                this.pharmacy_detail = response.data.data
            })
        },
        edit() {
            axios.post("patient/prescriptions/retrieve", {id:this.$route.params.id})
                    .then((response) => {
                         if (response.status == 200) {
                            this.$store.state.prescriptions.form = response.data.data;
                            // console.log(response.data.data)
                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
        },
        calculateNextRefilDate: _.debounce(function() {
            if (this.form.is_long_term || this.form.repeats >=1) {
                let nextRefillDate = new Date(this.current_date);
                switch (this.form.duration_unit) {
                    case 'day (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(this.form.duration));
                        console.log(nextRefillDate)
                        break;
                    case 'week (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(this.form.duration * 7));
                        break;
                    case 'month (s)':
                        nextRefillDate.setMonth(nextRefillDate.getMonth() + parseInt(this.form.duration));
                        break;
                    default:
                        break;
                }
                const year = nextRefillDate.getFullYear();
                const month = String(nextRefillDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
                const day = String(nextRefillDate.getDate()).padStart(2, '0');

                this.form.next_refill_date = `${year}-${month}-${day}`;
            } else {
                this.form.next_refill_date = ''
            }
            this.generateNotes()   
        }, 300),
        generateNotes: _.debounce(function() {
            let item = this.form
            let notes = ''
            if (item.drug_name) {
                notes += item.drug_name
            }
            if (item.method || parseInt(item.dosage) > 0 || item.dosage_unit || item.route || parseInt(item.duration) > 0 || item.duration_unit || item.frequency) {
                notes += "\n"
                if (item.method) {
                    notes += item.method
                }
                if (parseInt(item.dosage) > 0) {
                    notes += " "+parseInt(item.dosage)
                }
                if (item.dosage_unit) {
                    notes += " "+item.dosage_unit
                }
                if (item.route) {
                    notes += " "+item.route
                }
                if (item.frequency) {
                    notes += " "+item.frequency
                }
                if (parseInt(item.duration) > 0) {
                    notes += " for "+parseInt(item.duration)
                }
                if (item.duration_unit) {
                    notes += " "+item.duration_unit
                }
            }
            if (item.quantity && parseInt(item.quantity) > 0) {
                notes += "\nQuantity: "+item.quantity
            }
            if (item.repeats && parseInt(item.repeats) > 0) {
                notes += "\nRepeats: "+item.repeats
            }
            item.notes = notes
        }, 300),
        save(action=''){
            this.v$.$validate();
            const $this = this
            if (!this.v$.$error) {
                let url = 'patient/prescriptions/update'
                this.form.patient_id = this.$route.params.patient_id
                this.changeLoaderStatus(action, true)
                axios.post(url, this.form)
                .then((response) => {
                    this.changeLoaderStatus(action, false)
                    if (response.status == 200) {
                        this.loader = false;
                        this.$filters.moshaToast(response.data.message, "success");
                        if (action == 'save_print') {
                            console.log(response.data.data.result_data.patient_prescription_file);
                            this.documentPath = response.data.data.result_data.patient_prescription_file;
                            if (this.documentPath) {
                                this.modifyPdf();
                            }
                            this.sigImgBase64 = response.data.data.result_data.base64_sign;
                            window.$("#prescription-fax-model").modal("show");
                        } else {
                            $this.$router.push({ name: 'PatientEChartPrescriptionList', params: {patient_id: this.$route.params.patient_id } });
                        }

                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.changeLoaderStatus(action, false)
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

            } else {
                    for (const [key] of Object.entries(this.v$.form)) {
                        if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }
        },
        documentUrl(documentPath) {
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
        async modifyPdf() {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

				this.form.annotatedPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
				this.existingPdfBytes = this.form.annotatedPdfBytes;
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
				await this.pdfDoc.save();

				this.totalPages = this.pdfDoc.getPageCount();
				if(this.totalPages > 0)
				{
					this.isShowPagination = true;
				}
				this.renderPage(this.currentPage);
			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
		async renderPage(pageNumber, from = '') {
			try {
				await this.$nextTick();

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					// await this.retryGetCanvas();
					throw new Error('Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const context = canvas.getContext('2d');
				if (!context) {
					throw new Error('Unable to get 2D context of canvas.');
				}

				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;

				if (from != "next" && from != "previous" && from != "add-page") {
					await this.renderEditAnnotation();
				}

				if(!this.copiedElement) {
					this.resetSelectedElementObjAndId();
				}
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
        goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage, 'next');
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage, 'previous');
			}
		},
        changeLoaderStatus(action, status){
            if (action == 'save') {
                this.savingloader = status
            } else {
                this.savingPrintloader = status
            }
        },
        handleItemSelected(values) {
            let fieldNametxt = values.fieldName
            this.$store.state.prescriptions.form[fieldNametxt] = values.item.value
            this.calculateNextRefilDate()
            this.generateNotes()
            // const digit = values.fieldName.match(/^(\d+)\.(.+)/); // Regular expression to match digits at the beginning
            // if (digit) {
            //     this.prescriptionList[parseInt(digit[1])][digit[2]] = values.item.value;
            //     if (digit[2] == 'duration' || digit[2] == 'duration_unit') {
            //         this.calculateNextRefilDate(this.prescriptionList[parseInt(digit[1])])
            //     }
            //     this.generateNotes(this.prescriptionList[parseInt(digit[1])])
            // }
        },
        cancelFrm(){
            this.$router.push({ name: 'PatientEChartPrescriptionList', params: { patient_id: this.$route.params.patient_id} });
        },
        fetchPatientDetail() {
            let params = { 'id': this.$route.params.patient_id }
			axios.post("patient/detail", params)
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        getProvider(){
            axios.post("json_list/provider", { roleId: 1, 'id':this.form.provider_id })
                .then((response) => {
                this.provider_detail = response.data.data[0];
            })
        },
        printPreview() {
            const printContent = $(".prescription_html").html();
            // Create a new window for print preview
            const printWindow = window.open('', '_blank');

            // Write the HTML content to the print window
            printWindow.document.open();
            printWindow.document.write(`
            <html>
                <head>
                <title>Print Preview</title>
                </head>
                <body>
                ${printContent}
                </body>
            </html>
            `);
            printWindow.document.close();

            // Call the print function after a short delay to ensure the content is loaded
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 500);
        },
        doFax(){
            let url = "patient/prescriptions/fax"
            let form  = {
                patient_id:this.$route.params.patient_id
            }
            axios.post(url, form)
                .then((response) => {
                    if (response.status == 200) {
                        console.log("fax sent")
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

        },
        getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
                if (this.permissionList && this.permissionList.prescription_access) {
                    return true
                } else {
                    return false
                }
            },
            async getData() {
                await this.getPermissionList()
                await this.fetchPatientDetail()
                await this.getProvider()
                await this.getPreferredPharamcy()
                await this.edit();
                this.isDropdownVisible = true;
            }
    },
    mounted(){
        this.$store.state.loader = true;
        const pdfScript = document.createElement('script');
        pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
        // Letest
        // pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
        // client
        // pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';

        pdfScript.onload = () => {
            const workerScript = document.createElement('script');
            workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
            // Letest
            // workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
            // client
            // workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';

            workerScript.onload = () => {
                // Initialize your PDF logic here after both scripts are loaded
                // this.edit();  // Call this.edit() after both scripts are fully loaded
                this.getData()
            };

            document.head.appendChild(workerScript);
        };

        document.head.appendChild(pdfScript);
        this.getData()
        this.$store.state.loader = false;
    },
    computed: {
        form() {
			return this.$store.state.prescriptions.form;
		},
        containerLongtermClass() {
            return {
                'disable_input': !this.checkPermission(),
                'check_ntdsbl':!this.form.is_long_term
            };
        },
        containerSubClass(){
            return {
                'disable_input': !this.checkPermission(),
                'check_ntdsbl':!this.form.is_patient_compliant
            };
        },
        containerPastClass(){
            return {
                'disable_input': !this.checkPermission(),
                'check_ntdsbl':!this.form.is_past_med
            };
        }
    },
    // provide: {
	// 	PdfViewer: [ Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation ]
	// }
})
</script>
<style scoped>

</style>