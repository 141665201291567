<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10 edit_record_data preferred_pharmacy">
            <div class="edit_record" v-if="!this.isEditRecord">
                <a href="javascript:void(0);" class="" @click="editRecord"> Edit Record</a>
            </div>
            <div class="col-xxl-5 col-7 m-auto">
                <div class="preferred-prmcy-search">
                    <div class="search w-100" v-if="this.isEditRecord">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="searchPharamcy" class="form-control" placeholder="Search Pharmacy" v-model="pharmacy_search" @focus="searchPharamcy" ref="searchPharamcy">
                        <div class="search_drop_box" v-if="pharmacylist.length > 0 " :key="pharmacyKey">
                            <slot v-for="(item, index) in pharmacylist" :key="index">
                                <div class="con_drop_line d-flex justify-content-between align-items-center" @click="selectPharmacy(item)">
                                    <div class="man_haeding w-100">{{ item.pharmacy_name }} {{ item.state }} | <span>{{ item.address }} {{ item.city }}</span></div>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
                <div class="custom-form preferred-preferred-form" :class="{ 'readonly-form': !this.isEditRecord }">
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.pharmacy_name && this.form.pharmacy_name.length>0" :disabled="this.form.pharmacy_name && !this.form.pharmacy_name.length>0" v-model="form.pharmacy_name">
                        <label class="form-label">Pharmacy Name<small class="asterisksign">*</small> </label>
                        <ValidationErrorMessageList :errors="v$.form.pharmacy_name.$errors" />
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.address && this.form.address.length>0" :disabled="this.form.address && !this.form.address.length>0" v-model="form.address">
                        <label class="form-label">Address<small class="asterisksign">*</small> </label>
                        <ValidationErrorMessageList :errors="v$.form.address.$errors" />
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.city && this.form.city.length>0" :disabled="this.form.city && !this.form.city.length>0" v-model="form.city" >
                        <label class="form-label">City<small class="asterisksign">*</small> </label>
                        <ValidationErrorMessageList :errors="v$.form.city.$errors" />
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.state && this.form.state.length>0" :disabled="this.form.state && !this.form.state.length>0" v-model="form.state">
                        <label class="form-label">Province </label>
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.zip_code && this.form.zip_code.length>0" :disabled="this.form.zip_code && !this.form.zip_code.length>0" v-model="form.zip_code" >
                        <label class="form-label">Postal Code <small class="asterisksign">*</small> </label>
                        <ValidationErrorMessageList :errors="v$.form.zip_code.$errors" />
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.fax && this.form.fax.length>0" :disabled="this.form.fax && !this.form.fax.length>0" v-model="form.fax" >
                        <label class="form-label">Fax Number<small class="asterisksign">*</small> </label>
                        <ValidationErrorMessageList :errors="v$.form.fax.$errors" />
                    </div>
                    <div class="input_box">
                        <input type="text" class="form-control form-control-lg" required :readonly="this.form.phone && this.form.phone.length>0" :disabled="this.form.phone && !this.form.phone.length>0" v-model="form.phone">
                        <label class="form-label">Phone Number </label>
                    </div>
                    <div class="text-center btn_part" v-if="this.isEditRecord">
                        <button class="comman_brdr_btn big_btn mx36" @click="cancel"> Cancel </button>
                        <button class="comman_btn big_btn mx36" @click="save"> Save <img src="images/loader.gif" v-if="savingloader" /></button>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';

export default ({
    setup() {
        return { 
            v$: useVuelidate()
        }; 
    },
    validations() {
        return this.$store.state.pharmacy.validationRules;
    },
    data() {
        return {
            pharmacyKey: 0,
            isdisabledOnly: true,
            isEditRecord:false,
            pharmacylist:[],
            savingloader:false
        }
    },
    components: {
        sidebar,
        ValidationErrorMessageList
    },
    mounted() {
        this.getPatientPreferredPharmacy();
    },
    methods:{
        searchPharamcy(){
                axios.post('custom-pharmacy/list', {
                        patient_id: this.$route.params.patient_id,
                        keyword: this.pharmacy_search
                    })
                    .then((response) => {
                        if (response.status == 200) {
                            this.pharmacylist = response.data.data
                            this.pharmacyKey++
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
        },
        editRecord(){
            this.isEditRecord = true
            this.isdisabledOnly = false
        },
        cancel(){
            this.isEditRecord = false
            this.pharmacylist = []
            this.isdisabledOnly = true
        },
        getPatientPreferredPharmacy(){
            this.$store.state.loader = true;
            axios.post('patient/get-preferred-pharamcy', {
                    patient_id: this.$route.params.patient_id,
                })
                .then((response) => {
                    this.$store.state.loader = false;
                    if (response.status == 200) {
                        this.$store.state.pharmacy.form = response.data.data
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        selectPharmacy(item){
            this.pharmacy_search = ''
            this.pharmacylist = []
            this.$store.state.pharmacy.form = item
            this.pharmacyKey++
        },
        save(){
            this.v$.$validate();
            if (!this.v$.$error) {
                this.savingloader = true
                let url = 'custom-pharmacy/update'
                this.form.patient_id = this.$route.params.patient_id

                axios.post(url, this.form)
                .then((response) => {
                    this.savingloader = false
                    if (response.status == 200) {
                        this.$filters.moshaToast(response.data.message, "success");
                        this.cancel()

                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.savingloader = false
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });

            } else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
        }
    },
    computed: {
        form() {
			return this.$store.state.pharmacy.form;
		}
    },
})
</script>
