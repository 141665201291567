<template>
	<slot v-if="this.$route.meta.headerTitle != false">
		<PatientHeader :headerTitle="getHeaderTitle" @trigger-print-label-modal="openPrintLabelModal"></PatientHeader>
	</slot>
	<div class="content">
		<div :class="containerClass">
			<component :is="$store.state.component" @trigger-print-label-modal="openPrintLabelModal">

			</component>
		</div>
	</div>

	<PrintLabelModal ref="printLabelModal" :patientId="this.selectedPatientId" />

</template>
<script>
import PatientHeader from '../PatientHeader.vue';
import PatientCreate from '../../components/patient/Form.vue';
import PatientChart from '../../components/patient/Chart.vue';
import PatientAppointmentHistory from '../../components/patient/AppointmentHistory.vue';
import PatientEdit from '../../components/patient/Form.vue';
import PatientEChart from '../../components/patient/EChart.vue';
import axios from "@/scripts/axios.js"; 
import { mapActions } from 'vuex';
import PatientEChartAllergies from '../../components/patient/echart/allergies.vue';
import PatientEChartConsultList from '../../components/patient/echart/consultation/consultList.vue';
import PatientEChartConsultAdd from '../../components/patient/echart/consultation/consultAdd.vue';
import PatientEChartConsultEdit from '../../components/patient/echart/consultation/consultAdd.vue';
import PatientEChartSpecialistsList from '../../components/patient/echart/specialistsList.vue';
import PatientEChartFilesOverview from '../../components/patient/echart/files/filesOverview.vue';
import PatientEChartFilesEdit from '../../components/patient/echart/files/filesAdd.vue';
import PatientEChartFilesDelete from '../../components/patient/echart/files/filesOverview.vue';
import patientImagingList from '../../components/patient/echart/imaging.vue';
import patientLabsList from '../../components/patient/echart/LabList.vue';
import patientTemplateList from '../../components/patient/echart/template.vue';
import patientTemplateEdit from '../../components/patient/echart/templateEdit.vue';
import patientMeasurementList from '../../components/patient/echart/measurement/list.vue';
import patientMeasurementGroupList from '../../components/patient/echart/measurement/group-list.vue';
import editModuleTemplate from '../../components/patient/echart/templateModuleEdit.vue'
import PatientEChartPrescriptionList from '../../components/patient/echart/Medications/prescriptionList.vue'
import PatientEChartDrugList from '../../components/patient/echart/Medications/drugList.vue'
import PatientEChartPreferredPharmacy from '../../components/patient/echart/Medications/preferredPharmacy.vue'
import PatientEChartPrescriptionAdd from '../../components/patient/echart/Medications/prescriptionAdd.vue'
import PatientEChartPrescriptionView from '../../components/patient/echart/Medications/prescriptionView.vue'
import PatientEChartImmunizationList from '../../components/patient/echart/preventions/immunizationList.vue'
import PatientEChartScreeningList from '../../components/patient/echart/preventions/screeningList.vue';
import PatientEChartActiveTaskList from '../../components/patient/echart/activeTaskList.vue';
import PatientEChartCompleteDeleteTaskList from '../../components/patient/echart/completeDeleteTaskList.vue';
import PatientQueryTool from '../../components/patient/query-tool.vue';
import PatientEnrollmentHistory from '../../components/patient/enrollment-history/list.vue';
import EditPatientEnrollmentHistory from '../../components/patient/enrollment-history/form.vue';
import editModule from '../../components/patient/echart/templateModuleEdit.vue'
import PrintLabelModal from '../../components/base/PrintLabelModal.vue';

export default {
	...mapActions(['updatePatientHeaderData']),
	data() {
		return {
			selectedPatientId: null
		}
	},
	components: {
		PatientHeader,
		PatientCreate,
		PatientChart,
		PatientAppointmentHistory,
		PatientEdit,
		PatientEChart,
		PatientEChartAllergies,
		PatientEChartConsultList,
		PatientEChartConsultAdd,
		PatientEChartConsultEdit,

		PatientEChartPrescriptionList,
		PatientEChartDrugList,
		PatientEChartPreferredPharmacy,
		PatientEChartPrescriptionAdd,
		PatientEChartPrescriptionView,
		PatientEChartImmunizationList,
		PatientEChartScreeningList,



		PatientEChartSpecialistsList,
		PatientEChartFilesOverview,
		PatientEChartFilesEdit,
		PatientEChartFilesDelete,
		patientImagingList,
		patientLabsList,
		patientTemplateList,
		patientTemplateEdit,
		editModuleTemplate,
		editModule,
		patientMeasurementList,
		patientMeasurementGroupList,
		PatientEChartActiveTaskList,
		PatientEChartCompleteDeleteTaskList,

		PatientQueryTool,

		PatientEnrollmentHistory,
		EditPatientEnrollmentHistory,

		PrintLabelModal
	},
	created() {
		this.$store.state.component = this.$route.name;
	},
	mounted() {
		this.updatePatientHeaderData();
	},
	methods: {
		updatePatientHeaderData() {
			if ((this.$store.state.patient_id != "") && (this.$store.state.patient_id != this.$route.params.patient_id)) {
				axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
					.then((response) => {
						if (response.data.status === 200) {
							this.$store.state.patientHeaderData = response.data.data;
						} else {
							this.$filters.moshaToast(response.data.message, "error")
						}
					})
					.catch(error => {
						this.$filters.moshaToast(error.message, "error")
					});
			}
		},
		openPrintLabelModal(patientId) {
			this.selectedPatientId = patientId;
			this.$nextTick(() => {
				this.$refs.printLabelModal.openModal();
			});
		}
	},
	computed: {
		containerClass() {
			return {
				'container-fluid': (this.$route.name != 'PatientEChartPrescriptionView' && this.$route.name !=  'PatientEChartPrescriptionAdd'),
			};
		},
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
	},
}
</script>