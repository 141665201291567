<template>
	<div :class="divClass">
		<div class="form-group" :class="{ error: errors.length }">
			<label :class="labelClass">{{ title }}
				<small v-show="isRequired" class="asterisksign">*</small>
			</label>
			<div class="controls">
				<div class="syncfusion-component _datepic">
					<ejs-datepicker :placeholder="waterMark" :value="$filters.inputDateTime(modelValue)" :format="dateFormat" :openOnFocus="openOnFocus"  :max="maxDate" :min="minDate" @keyup="this.keyupdate('patient_status_date')" :ref="customID" :id="customID"  @change="() => { handleDate(customID); getdate($event); }"
					@blur="handleDate(customID)" @focus="openDatePicker(customID)"></ejs-datepicker>
				</div>
				<span class="form-text text-muted" v-if="notes != ''">{{ notes }}</span>
			</div>
			<div class="error-msg fs-14">
				<ul class="mb-0 p-0">
					<li class="d-block" v-for="(error, index) of errors" :key="index">
						{{ error.$message }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import moment from "moment";
import $ from "jquery";
export default {
	components: {
		'ejs-datepicker': DatePickerComponent,
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		modelValue: {
			type: [String,Date],
			default: ""
		},
		errors: {
			type: Array,
			default: () => []
		},
		labelClass: {
			type: String,
			default: "form-label"
		},
		divClass: {
			type: String,
			default: "col-md-6"
		},
		notes: {
			type: String,
			default: ""
		},
		minDate: {
			type: [Date,String],
			default: ""
		},
		maxDate: {
			type: [Date,String],
			default: ""
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		dateFormat: {
			type: String,
			default: 'yyyy-MM-dd'
		},
		waterMark: {
			type: String,
			default: 'Select a date'
		},
		openOnFocus: {
			type: Boolean,
			default: true
		},
		customID:{
			type: String,
			default: () =>{
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				let result = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * charactersLength))).join('');
				return result;
			}
		}
	},
	methods: {
		keyupdate(obj){
			if($(`#${obj}-error`)){
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{2})?(\d{2})?/, function(_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		getdate($event) {
			if ($event.value) {
				this.$emit('update:modelValue', moment($event.value).format('YYYY-MM-DD'));
			}
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		handleDate(obj){
			const sanitizedInput = $(`#${obj}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
			} else {
				if(formattedDate!=''){
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
		},
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
	},
	mounted() {
		var $this = this;
		$(document).on("click", '._datepic', function () {
			const inputField = $(this).find('input');
			const inputId = inputField.attr('id');
			$this.openDatePicker(inputId);
		});
	}
};
</script>