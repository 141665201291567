<template>
	<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 left_side_bar e_chart_sidebar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'AllConsultationList' }" v-if="this.$route.name === 'AllConsultationList' || this.$route.name === 'AllSpecialistList'">
					<router-link :to="{ name: 'AllConsultationList',params: { }}"> Consult</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllConsultationList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllSpecialistList' }" v-if="this.$route.name === 'AllConsultationList' || this.$route.name === 'AllSpecialistList'">
					<router-link :to="{ name: 'AllSpecialistList',params: { }}"> Specialists</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllSpecialistList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartConsultList' }" v-if="this.$route.name === 'PatientEChartConsultList' || this.$route.name === 'PatientEChartSpecialistsList'">
					<router-link :to="{ name: 'PatientEChartConsultList',params: { }}"> Consult</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartConsultList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartSpecialistsList' }"  v-if="this.$route.name === 'PatientEChartConsultList' || this.$route.name === 'PatientEChartSpecialistsList'">
					<router-link :to="{ name: 'PatientEChartSpecialistsList',params: { }}"> Specialists</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartSpecialistsList'">
				</li>
			</ul>
		</div>
	</div>
</template>
<script>


export default {
	data() {
		return {
			options:[
			]
		};
	},
	watch: {},
	mounted() {},
	methods: {},
	components: {}
};
</script>